$.fn.CMSTable = function(options) {

    var dataTable;
    var table = this;

    if ($.fn.DataTable.isDataTable( table ) ) {
        dataTable = $(table).DataTable();
        dataTable.destroy();
        console.log("datatable destroy")
    }

    var options = $.extend({
        renders: {},
        show_delete: true,
        show_edit: true,
        default_order: [[0, "desc"]],
        footerCallback: null
    }, options);

    var firstSearch = true;
    var columnDefs = [];
    var apiUrl = table.data("cms-table");


    table.find('th').each(function(i, o) {
        var col = $(o).data('column');
        if (col) {
            var def = {
                targets: i,
                data: col
            }
            if (col == 'id') {
                def.width = '60px';
                def.className = 'id-col';
            }
            if (options.renders[col]) {
                def.render = options.renders[col];
            }
            columnDefs.push(def)
        }
    });

    columnDefs.push({
        targets: -1,
        data: null,
        orderable: false,
        width: "100px",
        className: 'action-col',
        render: function(data, type, row, meta) {
            var btns =  (options.show_edit ? "<button class='btn btn-sm btn-primary mr-0 mr-sm-0 mr-md-0 mr-lg-0 mr-xl-1' data-cms-view title='Edit'><i class='fa fa-edit'></i></button>" : "") +
                        (options.show_delete ? "<button class='btn btn-sm btn-danger mt-1 mt-sm-1 mt-md-1 mt-lg-1 mt-xl-0' data-cms-delete title='Delete'><i class='fa fa-trash'></i></button>" : "");
            for(var i in options.actions){
                var disabled = false;
                var show = true;
                if(options.actions[i].show){
                    show = options.actions[i].show(row)
                }else if(options.actions[i].disable){
                    disabled = options.actions[i].disable(row)
                }
                if(show) {
                    btns += "<button class='btn btn-sm  " + options.actions[i].class + "' data-cms-button='" + i + "' title='" + (options.actions[i].title ? options.actions[i].title : '') + "' " + (disabled ? 'disabled' : '') + "><i class='glyphicon " + options.actions[i].icon + "'></i> "+(options.actions[i].text ? options.actions[i].text : '') +"</button>"
                }
            }
            return btns;
        }
    })

    dataTable = table.DataTable({
        drawCallback: function() {
            //$('table').parent().addClass('table-responsive');
        },
        footerCallback: options.footerCallback,
        autoWidth: false,
        serverSide: true,
        searching: true,
        pageLength: 50,
        order: options.default_order,
        ajax: {
            url: apiUrl,
            data: function(d) {

                if (!firstSearch) {
                    d.filters = $('[data-cms-filters]').serializeObject();
                }
            }
        },
        columnDefs: columnDefs,
        language: {
            "sEmptyTable": "Nessun dato presente nella tabella",
            "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
            "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
            "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "Visualizza _MENU_ elementi",
            "sLoadingRecords": "Caricamento...",
            "sProcessing": "Elaborazione...",
            "sSearch": "Cerca:",
            "sZeroRecords": "La ricerca non ha portato alcun risultato.",
            "oPaginate": {
                "sFirst": "Inizio",
                "sPrevious": "Precedente",
                "sNext": "Successivo",
                "sLast": "Fine"
            },
            "oAria": {
                "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
                "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
            }
        }
    });
    $(document).off('click', '[data-cms-button]');
    $(document).on('click', '[data-cms-button]', function() {
        var data = dataTable.row($(this).parents('tr')).data();
        var _id = data.id;
        var action = $(this).data('cms-button');
        for(var i in options.actions){
            if(i == action){
                options.actions[i].onClick(data, dataTable)
                break;
            }
        }
    });
    $(document).off('click', '[data-cms-view]');
    $(document).on('click', '[data-cms-view]', function() {
        var data = dataTable.row($(this).parents('tr')).data();
        var _id = data.id;
        window.location.href = apiUrl + '/' + _id + '/edit';
    });

    $(document).off('click', '[data-cms-delete]');
    $(document).on('click', '[data-cms-delete]', function() {
        var data = dataTable.row($(this).parents('tr')).data();
        var _id = data.id;
        if (confirm('Confermare eliminazione?')) {
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: apiUrl + '/' + _id,
                method: 'DELETE',
                success: function(response) {
                    console.log(response);
                    dataTable.ajax.reload();
                },
                dataType: 'json'
            })
        }
    });

    $('[data-cms-filters]').off('submit');
    $('[data-cms-filters]').on('submit', function(e) {
        e.preventDefault();
        firstSearch = false;
        dataTable.ajax.reload();
    });
    $('#accordionFilters').off('hide.bs.collapse').off('shown.bs.collapse');

    $('#accordionFilters').on('hide.bs.collapse', function() {
        $('.filters-arrow').addClass('glyphicon-chevron-down').removeClass('glyphicon-chevron-up')
    }).on('shown.bs.collapse', function() {
        $('.filters-arrow').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up')
    })

    return dataTable;
}
