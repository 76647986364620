window.uniqueID = function() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

$.fn.serializeObject = function() {
    var obj = {};
    $.each(this.serializeArray(), function(i, o) {
        var n = o.name.replace("[]", ""),
            v = o.value;

        if (v) {
            obj[n] =
                obj[n] === undefined ?
                v :
                $.isArray(obj[n]) ?
                obj[n].concat(v) :
                [obj[n], v];
        }
    });
    return obj;
};

$.delete = function (url){
    return $.ajax({
        url: url,
        method: 'DELETE',
        dataType: 'json'
    });
}
$.put = function (url, data){
    return $.ajax({
        url: url,
        data: data,
        method: 'PUT',
        dataType: 'json'
    });
}