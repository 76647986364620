// jQuery
window.$ = window.jQuery = require('jquery');

// Bootstrap
window.Popper = require('popper.js').default;
require('bootstrap');

// TinyMCE editor
require('tinymce/tinymce');

// Moment.js
window.moment = require('moment');
require('moment/locale/it');

// DataTables
require('datatables.net');
require('datatables.net-bs4');

// Select 2
require("select2");

// FlatPickr
require('flatpickr');

// Chart.js
require('chart.js');

// jQuery.Easing
require('jquery.easing');

// Table
require('./cms-common');
require('./cms-table');

// Polyfills
if ("ActiveXObject" in window) { // IE11
    console.warn('Import core-js')
    require('core-js');
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function() {
    if(typeof flatpickr !== 'undefined') flatpickr.localize( require("flatpickr/dist/l10n/it.js").default.it );
    $(".datepicker").flatpickr({
        altInput: true,
        altFormat: "d F Y",
        dateFormat: "Y-m-d",
        disableMobile: "true"
    });
    $(".datepicker-nopast").flatpickr({
        altInput: true,
        altFormat: "j F Y",
        dateFormat: "Y-m-d",
        minDate: 'today'
    });

    $('.select2').select2({
        placeholder: 'Cerca...',
        theme: 'bootstrap4',
    });

    // Start Bootstrap 2
    require('./sb-admin-2');
});